import React from "react"
import PropTypes from "prop-types"

const Marker = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="12px"
      height="15px"
      viewBox="0 0 12 15px"
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M1.76 10.9074C0.920141 10.0686 0.347955 8.99949 0.115838 7.8354C-0.116279 6.67131 0.0021052 5.46453 0.456012 4.36773C0.909918 3.27094 1.67895 2.33343 2.66581 1.67381C3.65266 1.01419 4.813 0.662109 6 0.662109C7.18701 0.662109 8.34734 1.01419 9.3342 1.67381C10.3211 2.33343 11.0901 3.27094 11.544 4.36773C11.9979 5.46453 12.1163 6.67131 11.8842 7.8354C11.6521 8.99949 11.0799 10.0686 10.24 10.9074L6.47334 14.6807C6.41136 14.7432 6.33763 14.7928 6.25639 14.8267C6.17515 14.8605 6.08801 14.8779 6 14.8779C5.912 14.8779 5.82486 14.8605 5.74362 14.8267C5.66238 14.7928 5.58864 14.7432 5.52667 14.6807L1.76 10.9074ZM9.3 9.96739C9.9526 9.31473 10.397 8.4832 10.577 7.57797C10.7571 6.67274 10.6646 5.73445 10.3114 4.88176C9.95817 4.02907 9.36003 3.30026 8.59262 2.7875C7.8252 2.27474 6.92296 2.00106 6 2.00106C5.07704 2.00106 4.17481 2.27474 3.40739 2.7875C2.63997 3.30026 2.04183 4.02907 1.68861 4.88176C1.33539 5.73445 1.24294 6.67274 1.42297 7.57797C1.603 8.4832 2.04741 9.31473 2.7 9.96739L6 13.2674L9.3 9.96739ZM6 9.33406C5.29276 9.33406 4.61448 9.05311 4.11438 8.55301C3.61429 8.05291 3.33334 7.37463 3.33334 6.66739C3.33334 5.96015 3.61429 5.28187 4.11438 4.78177C4.61448 4.28167 5.29276 4.00072 6 4.00072C6.70725 4.00072 7.38552 4.28167 7.88562 4.78177C8.38572 5.28187 8.66667 5.96015 8.66667 6.66739C8.66667 7.37463 8.38572 8.05291 7.88562 8.55301C7.38552 9.05311 6.70725 9.33406 6 9.33406ZM6 8.00072C6.35363 8.00072 6.69276 7.86025 6.94281 7.6102C7.19286 7.36015 7.33334 7.02101 7.33334 6.66739C7.33334 6.31377 7.19286 5.97463 6.94281 5.72458C6.69276 5.47453 6.35363 5.33406 6 5.33406C5.64638 5.33406 5.30724 5.47453 5.05719 5.72458C4.80715 5.97463 4.66667 6.31377 4.66667 6.66739C4.66667 7.02101 4.80715 7.36015 5.05719 7.6102C5.30724 7.86025 5.64638 8.00072 6 8.00072Z" fill={color}/>
    </svg>
  )
}

Marker.propTypes = {
  color: PropTypes.string,
}

Marker.defaultProps = {
  color: "#000000",
}

export default Marker
