import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import CreatableSelect from 'react-select/creatable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Wart from "components/icons/wart"
import List from "./list"
import SearchLocationInput from 'components/common/searchLocationInput'
import Spinner from 'components/common/spinner'
import ErrorMessage from "components/common/errorMessage"

const StyledContainer = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  }
`
const StyledWrapper = styled.div`
  background: white;
  min-height: 20rem;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  margin-bottom: 2rem;
  .form__body {
    padding: 1.5rem;
    .label {
      color: ${({ theme }) => theme.colors.zodiacLight};
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: 0.55rem;
    }

    .subtitle {
      color: ${({ theme }) => theme.colors.tertiary};
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin-bottom: 1.2rem;
    }
  }
  .form__footer {
    padding: 1.3rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #EBEFF9;
  }
  .jobs {
    padding: 1.7rem;
    .label {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #183D63;
      margin-bottom: 1.7rem;
    }
  }
`
const InputElement = styled.input`
  outline: none;
  border: 1px solid #ccc;
  background-color: white;
  font: inherit;
  padding: 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  background: #F9FCFF;
  border: 1px solid #CBD5E0;
  border-radius: 0.25rem;
  color: #183D63;
  font-size: 0.9rem;
  line-height: 1.1rem;
  &:focus {
      outline: none;
      background-color: #ccc;
  }
`

const Small = styled.small`
    opacity: 0.6;
    cursor: pointer;
    position: absolute;
`

const StyledButton = styled.button`
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: #22B5F3;
  border: none;
  cursor: pointer;
`

const Filters =  styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .control {

    margin-bottom: 1rem;
    flex: 1 1 100%;
    width: 100%;
    .label {
      color: ${({ theme }) => theme.colors.dodger};
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
    .select__control {
      background: #F9FCFF;
      border: 1px solid #CBD5E0;
      border-radius: 0.25rem;
      color: #183D63;
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
  }
  .input {
    .css-yk16xz-control {
      background: #F9FCFF;
      border: 1px solid #CBD5E0;
      border-radius: 0.25rem;
      color: #183D63;
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .control {
      flex: 1 1 30%;
      width: 30%;
      &:nth-of-type(2) {
        margin: 0 1rem 1rem 1rem;
      }
      margin-bottom: 1rem;
    }
  }
`


const components = {
  DropdownIndicator: null,
};

const createOption = label => ({
  label,
  value: label,
});

const Searching = (props) => {
  const [title, setTitle] = useState("")
  const [location, setLocation] = useState(null)
  const [skills, setSkills] = useState([])
  const [inputSkill, setInputSkill] = useState('')
  const inputSearchRef = useRef();
  
  useEffect(() => {
    props.fetchJobs({
      skills: skills && skills.length && skills.map(skill => {
        return { name: skill.label, value: null }}) || [],
      location: location && {lat: location.lat, lng: location.lng},
      title: title
    })
  }, [])

/*   useEffect(() => {
    const timer = setTimeout(() => {
      if (title === inputSearchRef.current.value) {
        props.fetchJobs({
          skills: skills && skills.length && skills.map(skill => {
            return { name: skill.label, value: null }}) || [],
          location: location,
          title: title
        })
      }
    }, 500)
    return () => {
      clearTimeout(timer);
    };
  }, [title, inputSearchRef, props.fetchJobs]) */

  const handleInputTitleChange = value => {
    setTitle(value)
  }
  const handleSkillsChange = (value, actionMeta) => {
    setSkills(value)
  };

  const handleInputSkillChange = inputValue => {
    setInputSkill(inputValue)
  };

  const handleSkillKeyDown = event => {
    if (!inputSkill) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if(skills instanceof Array) {
          setSkills([...skills, createOption(inputSkill)])
        } else {
          setSkills([createOption(inputSkill)])
        }
        setInputSkill('')
        event.preventDefault();
    }
  };

  const handleChangeLocationInput = location => {
    setLocation(location)
  } 

  const searchHandler = () => {
    props.fetchJobs({
      skills: skills && skills.length && skills.map(skill => {
        return { name: skill.label, value: null }}) || [],
      location: location && {lat: location.lat, lng: location.lng},
      title: title
    })
  }
  console.log('props jobs', props.jobs)
  return (
    <StyledContainer
      color="#22B5F3"
    >
      <StyledWrapper>
        <div className="form__body">
          <div className="label">
            Select criterias
          </div>
          <div className="subtitle">
              Job Listing/Marketplace Search Demo powered by HrFlow.ai Job Searching API.
          </div>
          <Filters>
            <div className="control">
              <label className="label">Job title</label>
              <InputElement
                onChange={event => handleInputTitleChange(event.target.value)}
                type="input"
                ref={inputSearchRef}
                placeholder="Data Scientist"
              />
            </div>
            <div className="control mid" >
              <label className="label">Location</label>
              <SearchLocationInput
                changeLocation={location => handleChangeLocationInput(location)}
              />
              {location && location.text && (
                <Small onClick={() => setLocation({})}>
                  <FontAwesomeIcon className="mr-05" icon={faTimes} />
                  {location && location.text}
                </Small>
              )}
            </div>
            <div className="control">
              <label className="label">Skills</label>
              <CreatableSelect
                components={components}
                inputValue={inputSkill}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={handleSkillsChange}
                onInputChange={handleInputSkillChange}
                onKeyDown={handleSkillKeyDown}
                placeholder="python, ruby"
                value={skills}
                className="input"
              />
            </div>
          </Filters>
        </div>
        <div className="form__footer">
            <StyledButton
              onClick={searchHandler}
            >
              <span className="mr-1">Search</span><Wart color="#FFF" />
            </StyledButton>
          </div>
      </StyledWrapper>
      <StyledWrapper>
        <div className="jobs">
          <div className="label">Jobs</div>
          {props.jobs?.r && (
            <Spinner />
          )}
          {props.jobs?.s && (
            <List skills={skills && skills.length && skills.map(skill => skill.label.toLowerCase()) || []} jobs={props.jobs.payload.jobs} />
          )}
          {props.jobs?.f && (
            <ErrorMessage
              title="Something went wrong"
              subtitle="Please retry again later."
              type="error"
              min="true"
            />
          )}
        </div>
      </StyledWrapper>
    </StyledContainer>
  )
}


export default Searching