import React from "react"
import PropTypes from "prop-types"

const Briefcase = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="14px"
      height="12px"
      viewBox="0 0 14 12px"
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M4.33325 2.66667V1.33333C4.33325 0.6 4.93325 0 5.66659 0H8.33325C8.68687 0 9.02601 0.140476 9.27606 0.390524C9.52611 0.640573 9.66659 0.979711 9.66659 1.33333V2.66667H12.3333C12.6869 2.66667 13.026 2.80714 13.2761 3.05719C13.5261 3.30724 13.6666 3.64638 13.6666 4V10.6667C13.6666 11.0203 13.5261 11.3594 13.2761 11.6095C13.026 11.8595 12.6869 12 12.3333 12H1.66659C1.31296 12 0.973825 11.8595 0.723776 11.6095C0.473728 11.3594 0.333252 11.0203 0.333252 10.6667V4C0.333252 3.26667 0.933252 2.66667 1.66659 2.66667H4.33325ZM9.66659 4H4.33325V10.6667H9.66659V4ZM10.9999 4V10.6667H12.3333V4H10.9999ZM2.99992 4H1.66659V10.6667H2.99992V4ZM5.66659 2.66667H8.33325V1.33333H5.66659V2.66667Z" fill={color}/>
    </svg>
  )
}

Briefcase.propTypes = {
  color: PropTypes.string,
}

Briefcase.defaultProps = {
  color: "#000000",
}

export default Briefcase
