import React from 'react'
import styled from "styled-components"
import Marker from "components/icons/marker"
import Briefcase from "components/icons/briefcase"
import Clock from "components/icons/clock"
import ErrorMessage from "components/common/errorMessage"



const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`

const StyledJob = styled.div`
  width: 50%;
  flex: 0 1 50%;
  padding: 0.5rem;
  @media (max-width: 1200px) {
    width: 100%;
    flex: 1 1 100%;
  }
  .job {
    height: 100%;
    width: 100%;
    background: #F8FBFF;
    border: 1px solid #CBD5E0;
    border-radius: 0.25rem;
    .job__header {
      padding: 1rem;
      .company {
        font-size: 0.8rem;
        line-height: 1rem;
        color: #1DAFB6;
        margin-bottom: 0.5rem;
      }
      .title {
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #1A212D;
        max-width: 12rem;
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          max-width: 40rem;
        }
      }
    }
    .job__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      background: white;
      border-top: 1px solid #CBD5E0;
      border-bottom: 1px solid #CBD5E0;
      .item {
        font-size: 0.8rem;
        line-height: 1rem;
        color: #0B2540;
        margin-bottom: 0.5rem;
        &:last-of-type {
          margin-bottom: 0;
        }
        span {
          margin-left: 0.7rem;
        }
      }
    }
    .job__footer {
      padding: 1rem;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: -0.12rem;
      height: unset;
      max-height: unset;
      overflow: hidden;
      .skill {
        color: #1DAFB6;
        text-align: center;
        font-size: 0.75rem;
        line-height: 0.95rem;
        padding: 0.3rem;
        background: #EAFEFF;
        border: 1px solid #1DAFB6;
        border-radius: 0.25rem;
        margin: 0.12rem;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        height: 7.9375rem;
        max-height: 7.9375rem;
      }
    }
  }
`

const List = (props) => {
  if (props.jobs.length) {
    return (
      <StyledWrapper>
        {
          props.jobs.map(job => {
            if (job.id !== 16208) {
              const name = job.name
              const location = job.location.text
              const company = job.tags.filter(tag => tag.name === 'company')[0].value
              const category = job.tags.filter(tag => tag.name === 'category')[0].value
              const type = job.tags.filter(tag => tag.name === 'type')[0].value
  
              return (
                <StyledJob key={job.key}>
                  <div className="job">
                    <div className="job__header">
                      <div className="company ellipsis">
                        {company}
                      </div>
                      <div className="title ellipsis">
                        {name}
                      </div>
                    </div>
                    <div className="job__body">
                      <span className="item ellipsis">
                        <Marker color="#A0AEC0" />
                        <span>{location}</span>
                      </span>
                      <span className="item ellipsis">
                        <Briefcase color="#A0AEC0" />
                        <span>{category}</span>
                      </span>
                      <span className="item ellipsis">
                        <Clock color="#A0AEC0" />
                        <span>{type}</span>
                      </span>
                    </div>
                    <div className="job__footer">
                      {
                          job.skills.map(skill => {
                            const activeClass = props.skills.indexOf(skill.name.toLowerCase()) !== -1 ? "skill active" : 'skill';
                            return (
                              <span key={skill.name} className={activeClass}>{skill.name}</span>
                            )
                          })
                      }
                    </div>
                  </div>
                </StyledJob>
              )
            }
          })
        }
      </StyledWrapper>
    )
  }
  return (
    <ErrorMessage
      title="No job found!"
      subtitle="Please try adjusting your criterias"
      type="empty"
      min="true"
    /> 
  )

}

export default List;