import React from "react"
import PropTypes from "prop-types"

const Wart = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
    >
  <path xmlns="http://www.w3.org/2000/svg" d="M14.32 12.8997L19.71 18.2997C19.8725 18.4922 19.9565 18.7389 19.945 18.9905C19.9335 19.2422 19.8275 19.4802 19.6481 19.6571C19.4687 19.834 19.2292 19.9366 18.9774 19.9445C18.7256 19.9524 18.4801 19.865 18.29 19.6997L12.91 14.3197C11.3027 15.5683 9.27992 16.1573 7.25358 15.9669C5.22725 15.7764 3.34968 14.8208 2.00319 13.2946C0.656705 11.7684 -0.0574636 9.78637 0.00610398 7.75209C0.0696715 5.71782 0.906196 3.78425 2.34535 2.3451C3.7845 0.905951 5.71806 0.0694274 7.75234 0.00585984C9.78661 -0.0577077 11.7686 0.656461 13.2948 2.00295C14.821 3.34944 15.7766 5.227 15.9671 7.25334C16.1576 9.27967 15.5685 11.3024 14.32 12.9097V12.8997ZM7.99995 13.9997C9.59125 13.9997 11.1174 13.3676 12.2426 12.2423C13.3678 11.1171 14 9.59101 14 7.99971C14 6.40841 13.3678 4.88229 12.2426 3.75707C11.1174 2.63185 9.59125 1.99971 7.99995 1.99971C6.40865 1.99971 4.88253 2.63185 3.75731 3.75707C2.63209 4.88229 1.99995 6.40841 1.99995 7.99971C1.99995 9.59101 2.63209 11.1171 3.75731 12.2423C4.88253 13.3676 6.40865 13.9997 7.99995 13.9997Z" fill={color} />
</svg>
  )
}

Wart.propTypes = {
  color: PropTypes.string,
}

Wart.defaultProps = {
  color: "#000000",
}

export default Wart