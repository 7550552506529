import React from "react"
import PropTypes from "prop-types"

const Clock = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M6.99992 13.6673C5.23181 13.6673 3.53612 12.9649 2.28587 11.7147C1.03563 10.4645 0.333252 8.76876 0.333252 7.00065C0.333252 5.23254 1.03563 3.53685 2.28587 2.28661C3.53612 1.03636 5.23181 0.333984 6.99992 0.333984C8.76803 0.333984 10.4637 1.03636 11.714 2.28661C12.9642 3.53685 13.6666 5.23254 13.6666 7.00065C13.6666 8.76876 12.9642 10.4645 11.714 11.7147C10.4637 12.9649 8.76803 13.6673 6.99992 13.6673ZM6.99992 12.334C8.41441 12.334 9.77096 11.7721 10.7712 10.7719C11.7713 9.77169 12.3333 8.41514 12.3333 7.00065C12.3333 5.58616 11.7713 4.22961 10.7712 3.22941C9.77096 2.22922 8.41441 1.66732 6.99992 1.66732C5.58543 1.66732 4.22888 2.22922 3.22868 3.22941C2.22849 4.22961 1.66659 5.58616 1.66659 7.00065C1.66659 8.41514 2.22849 9.77169 3.22868 10.7719C4.22888 11.7721 5.58543 12.334 6.99992 12.334ZM7.66659 6.72732L9.35992 8.41398C9.48545 8.53952 9.55598 8.70978 9.55598 8.88732C9.55598 9.06485 9.48545 9.23512 9.35992 9.36065C9.23438 9.48619 9.06412 9.55671 8.88659 9.55671C8.70905 9.55671 8.53879 9.48619 8.41325 9.36065L6.53325 7.46732C6.40764 7.34423 6.33576 7.1765 6.33325 7.00065V4.33398C6.33325 4.15717 6.40349 3.9876 6.52851 3.86258C6.65354 3.73756 6.82311 3.66732 6.99992 3.66732C7.17673 3.66732 7.3463 3.73756 7.47132 3.86258C7.59635 3.9876 7.66659 4.15717 7.66659 4.33398V6.72732Z" fill={color}/>
    </svg>
  )
}

Clock.propTypes = {
  color: PropTypes.string,
}

Clock.defaultProps = {
  color: "#000000",
}

export default Clock
